import {
  Fragment, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Button-Control icons/Expand icon.svg';
import { ReactComponent as NextLevelIcon } from '../../assets/icons/Button-Control icons/Next level icon.svg';
import {
  DEFAULT_HARDWARE_GROUP,
  useHardwareSummary,
} from '../../shared/appUIFramework/appBackend/useHardwareSummary';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import {
  CanBoundHardware,
} from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import AppSearch from '../../shared/appUIFramework/components/AppSearch';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { getShouldConnectToSystemAfterCreation, resetShouldConnectToSystem } from './components/SystemForm';
import SystemTabsHeader from './components/SystemTabsHeader';
import {
  AccountStatusUIState,
  applyAccountStatusStyles,
  useAccountInfo,
} from '../../shared/appUIFramework/appBackend/useAccountInfo';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import { AppSortIcon } from '../../shared/appUIFramework/components/SortAndFilterIcons';
import callIfEnterKeyPressed from '../../shared/jsUtils/callIfEnterKeyPressed';
import {
  RefreshButton,
  useGroupManagement,
  useHardwareSearch,
  useHardwareSort,
  useSiteId,
  connectPanel,
} from './components/systemHardware.methods';
import { applyAddPaddingLeftWhenScrollIsVisible } from '../../styles';
import { DeviceTypeIds } from './models/IHardware';
import { getCallName } from './components/entryUserForm.monitors';

function getDeviceTypeTranslation(deviceTypeId: DeviceTypeIds) {
  return {
    [DeviceTypeIds.Unknown]: 'Unknown',
    [DeviceTypeIds.Mark1Panel]: 'StandardPanel',
    [DeviceTypeIds.Mark1Monitor]: 'StandardMonitor',
    [DeviceTypeIds.Mark2Panel]: 'StandardPanel',
    [DeviceTypeIds.TouchPanel]: 'TouchPanel',
    [DeviceTypeIds.PremiumMonitor]: 'PremiumMonitor',
    [DeviceTypeIds.Mark2Monitor]: 'StandardMonitor',
    [DeviceTypeIds.AudioOnly]: 'AudioMonitor',
    [DeviceTypeIds.Mobile]: 'Mobile',
    [DeviceTypeIds.SIP]: 'SIP',
  }[deviceTypeId];
}

export default function SystemTabsHardware() {
  const { t } = useTranslation();
  const siteId = useSiteId();
  const {
    hardwareGroups,
    reset: setUndefinedHardware,
    mutate: refreshHardwareSummary,
    setEmptyHardware,
    isSiteBound,
    mobileUsersCount,
    isOffline,
    isLoading,
    refreshSiteInfo,
  } = useHardwareSummary(siteId);
  const {
    getSortedOption,
    buildFieldSortFunction,
    getOptions,
    getOptionLabel,
    isSortedBy,
    hardwareGroupsSorted,
  } = useHardwareSort(hardwareGroups);
  const {
    searchHardware,
    hardwareGroupsFiltered,
  } = useHardwareSearch(hardwareGroupsSorted);

  const { hardwareGroupsCollapsed, toggledHardwareGroupCollapsed } = useGroupManagement();
  const { accountInfo } = useAccountInfo();
  const isAccountFunctionalityLimited = accountInfo.accountStatus === AccountStatusUIState.Limited;

  const [, setLocation] = useLocation();
  const [shouldGoToMobileUsers, setShouldGoToMobileUsers] = useState(false);

  // that is the valid way to navigate from that page, otherwise we have errors about less hooks calls
  useEffect(() => {
    if (shouldGoToMobileUsers) {
      setLocation(`/systems/${siteId}/entry-users/0`);
    }
  }, [shouldGoToMobileUsers]);

  const showConnectDevicePopup = async () => {
    if (isAccountFunctionalityLimited) {
      return;
    }

    if (mobileUsersCount == null) {
      throw new Error('Mobile users count is not defined');
    }

    if (!siteId) {
      throw new Error('Site ID is not defined');
    }

    connectPanel({
      mobileUsersCount,
      siteId,
      navigateToMobileUsers: () => {
        setShouldGoToMobileUsers(true);
      },
      onConnected: async () => {
        setUndefinedHardware();
        await Promise.all([
          refreshHardwareSummary(),
          refreshSiteInfo && refreshSiteInfo((siteInfo) => {
            if (siteInfo) {
              return {
                ...siteInfo,
                isSiteBound: true,
              };
            }
            return siteInfo;
          }, {
            revalidate: false,
          }),
        ]);
      },
    });
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (getShouldConnectToSystemAfterCreation()) {
      showConnectDevicePopup();
    }

    resetShouldConnectToSystem();
  }, [isLoading, showConnectDevicePopup]);

  const onlyDefaultGroupExists = hardwareGroups
    && Object.keys(hardwareGroups).length === 1
    && Object.keys(hardwareGroups)[0] === DEFAULT_HARDWARE_GROUP;

  return (
    <>
      <SystemTabsHeader activeTab="hardware" />
      <div className="app-content app-pt-20 app-pb-33 app-px-30">
        <div
          className="app-systems-hardware-header app-mb-26 app-d-flex app-align-items-center app-justify-content-between"
        >
          <AppSearch
            onSearch={searchHardware}
            placeholder={t('Sites_SearchHardware')}
          />
          <div className="app-d-flex">
            <RefreshButton
              disabled={!isSiteBound || isAccountFunctionalityLimited}
              {...applyAccountStatusStyles(accountInfo)}
              refresh={refreshHardwareSummary}
              setEmptyHardware={setEmptyHardware}
            />
            <AppPermissionComponentGuard permissions={CanBoundHardware}>
              <button
                type="button"
                {...applyAccountStatusStyles(accountInfo)}
                className="app-primary-button"
                onClick={showConnectDevicePopup}
                disabled={isAccountFunctionalityLimited}
              >
                {t(isSiteBound ? 'ReplacePanel' : 'SiteAdd_ConnectToSite')}
              </button>
            </AppPermissionComponentGuard>
          </div>
        </div>

        <AppShowLoading showLoading={isLoading}>
          <div className="app-flex-vertical-scrollable">
            <div className="app-table">
              {isOffline
                && (
                  <div
                    className="app-flex-vertical-scrollable app-align-items-center app-bold-26 app-justify-content-center app-d-flex"
                  >
                    <span>{t('DeviceIsOffline')}</span>
                  </div>
                )}
              {!isOffline && (
                <div className="app-table-header-row app-table-5-cols">
                  <div>{t('Table_Group')}</div>
                  <div className="app-table-header-row-with-sort">
                    <span className="app-mr-9">{t('Table_ID')}</span>
                    <AppPopupMenu
                      options={getOptions()}
                      selectedOption={getSortedOption('deviceId')}
                      onOptionSelected={buildFieldSortFunction('deviceId')}
                      getOptionLabel={getOptionLabel}
                      render={() => <AppSortIcon applied={isSortedBy('deviceId')} />}
                    />
                  </div>
                  <div className="app-table-header-full">{t('Table_DeviceName')}</div>
                  <div className="app-table-header-limited">{t('Table_Name')}</div>
                  <div className="app-table-header-full">{t('Table_DeviceType')}</div>
                  <div className="app-table-header-limited">{t('Table_Type')}</div>
                  <div className="app-table-header-full">{t('Table_SerialNumber')}</div>
                  <div className="app-table-header-limited">{t('Table_Serial')}</div>
                </div>
              )}
              <div
                className="app-flex-vertical-scrollable"
                {...applyAddPaddingLeftWhenScrollIsVisible(isLoading)}
              >
                {hardwareGroupsFiltered && onlyDefaultGroupExists && (
                  hardwareGroupsFiltered[DEFAULT_HARDWARE_GROUP]?.map((hardware) => (
                    <div
                      className="app-table-content-row app-table-5-cols"
                      role="row"
                      data-hover-disabled="true"
                      key={`${DEFAULT_HARDWARE_GROUP}${hardware.deviceId}${hardware.serialNumber}`}
                    >
                      <div />
                      <div>{hardware.deviceId}</div>
                      <div>
                        {
                          hardware.deviceTypeId === DeviceTypeIds.Mark1Panel
                            || hardware.deviceTypeId === DeviceTypeIds.Mark2Panel
                            || hardware.deviceTypeId === DeviceTypeIds.TouchPanel
                            ? hardware.deviceName
                            : getCallName(hardwareGroupsFiltered[DEFAULT_HARDWARE_GROUP] || [], hardware.deviceId, hardware.group, hardware.deviceName)
                        }
                      </div>
                      <div>{t(getDeviceTypeTranslation(hardware.deviceTypeId))}</div>
                      <div>{hardware.serialNumber}</div>
                    </div>
                  ))
                )}
                {hardwareGroupsFiltered && !onlyDefaultGroupExists && Object.keys(hardwareGroupsFiltered).map((group) => (
                  <Fragment key={group}>
                    <div
                      className="app-table-content-row app-table-5-cols"
                      role="button"
                      tabIndex={0}
                      onKeyDown={callIfEnterKeyPressed(() => toggledHardwareGroupCollapsed(group))}
                      onClick={() => toggledHardwareGroupCollapsed(group)}
                    >
                      <div className="app-d-flex app-align-items-center">
                        <div className="app-mr-9 app-w-20">
                          {hardwareGroupsCollapsed[group]
                            ? <ExpandIcon /> : <NextLevelIcon />}
                        </div>
                        <div>{group === DEFAULT_HARDWARE_GROUP ? t('Hardware_Default') : group}</div>
                      </div>
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>

                    {hardwareGroupsCollapsed[group] && hardwareGroupsFiltered[group].map((hardware) => (
                      <div
                        className="app-table-content-row app-table-5-cols"
                        key={`${group}${hardware.deviceId}${hardware.serialNumber}`}
                        role="row"
                        data-hover-disabled="true"
                      >
                        <div />
                        <div>{hardware.deviceId}</div>
                        <div>{getCallName(hardwareGroupsFiltered[group] || [], hardware.deviceId, hardware.group, hardware.deviceName)}</div>
                        <div>{t(getDeviceTypeTranslation(hardware.deviceTypeId))}</div>
                        <div>{hardware.serialNumber}</div>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </AppShowLoading>
        {isLoading && (
          <div className="app-loading-text app-uppercase app-weight-600">
            {t('LoadingHardware')}
            <br />
            {t('ThisCanTake60Seconds')}
          </div>
        )}
      </div>
    </>
  );
}
