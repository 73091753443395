import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useRoute } from 'wouter';
import { closePopup, isPopupShown, showPopup } from '../../../shared/appUIFramework/popup/AppPopup';
import ConnectDeviceProcessingPopup from './ConnectDeviceProcessingPopup';
import ConnectDeviceSuccessPopup from './ConnectDeviceSuccessPopup';
import AppSystemErrorPopup from '../../../shared/appUIFramework/appGenericErrorPopup/AppSystemErrorPopup';
import { DEFAULT_HARDWARE_GROUP, HardwareGroupsType } from '../../../shared/appUIFramework/appBackend/useHardwareSummary';
import { IHardwareDevice } from '../models/IHardware';
import useSortNumericOptions, { SortNumericOptions } from '../../../shared/appUIFramework/hooks/useSortNumericOptions';
import ConnectDevicePopup from './ConnectDevicePopup';
import ConnectDeviceAddMobileUsersPopup from './ConnectDeviceDoYouWishToAddFirstMobileUserPopup';
import { useAppTableState } from '../../../shared/appUIFramework/components/AppTablePage';

interface IHardwareSortField {
  field: keyof IHardwareDevice;
  sort: SortNumericOptions;
}

export function RefreshButton({
  refresh,
  setEmptyHardware,
  className,
  disabled: forceDisabled,
}: {
  refresh: () => Promise<any>,
  setEmptyHardware: () => void,
  className?: string,
  disabled?: boolean
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const refreshHardware = async () => {
    setDisabled(true);
    try {
      const showProcessingPopupPromise = showPopup({
        reactNode: <ConnectDeviceProcessingPopup />,
        disableCloseOnOverlay: true,
        disableCloseOnEscape: true,
      });
      const delay = async (ms: number) => new Promise((resolve) => setTimeout(() => resolve(true), ms));
      let hardwareRefreshed = false;
      const refreshHardwarePromise = Promise.all([delay(2000), refresh()])
        .then(() => { hardwareRefreshed = true; })
        .catch((err) => { throw err; });
      await Promise.race([showProcessingPopupPromise, refreshHardwarePromise]);
      if (hardwareRefreshed) {
        await showPopup(<ConnectDeviceSuccessPopup />);
      }
    } catch (error) {
      if (isPopupShown()) {
        closePopup();
      }
      setEmptyHardware();
      await showPopup(<AppSystemErrorPopup />);
    } finally {
      setDisabled(false);
    }
  };
  return (
    <button
      disabled={forceDisabled || disabled}
      onClick={refreshHardware}
      type="button"
      className={`app-primary-button app-mr-9 ${className}`}
    >
      {t('ViewSiteHardware_Refresh')}
    </button>
  );
}

export function useGroupManagement() {
  const [hardwareGroupsCollapsed, setHardwareGroupsCollapsed] = useState<Record<string, boolean>>({});
  const toggledHardwareGroupCollapsed = (hardwareGroup: string) => {
    setHardwareGroupsCollapsed({
      ...hardwareGroupsCollapsed,
      [hardwareGroup]: !hardwareGroupsCollapsed[hardwareGroup],
    });
  };
  return { hardwareGroupsCollapsed, toggledHardwareGroupCollapsed };
}

export function useSiteId() {
  const [, params] = useRoute('/systems/:siteId/hardware');
  return params?.siteId || '';
}

export function useHardwareSearch(hardwareGroups?: HardwareGroupsType) {
  const [hardwareSearch, setHardwareSearch] = useState('');

  const applyHardwareSearch = (groups: HardwareGroupsType, search: string) => {
    if (!hardwareSearch) {
      return groups;
    }
    const searchLower = search.toLowerCase();

    const hardwareGroupsSearched: { [key: string]: IHardwareDevice[] } = {};
    Object.entries(groups).forEach(([groupName, hardwareItems]) => {
      const hardwareItemsSearched = hardwareItems.filter((hardwareItem) => {
        const hardwareItemName = (hardwareItem.deviceName || '').toLowerCase();
        const hardwareItemSerial = (hardwareItem.serialNumber || '').toLowerCase();
        const hardwareItemId = (hardwareItem.deviceId || '').toLowerCase();
        return hardwareItemName.includes(searchLower)
          || hardwareItemSerial.includes(searchLower)
          || hardwareItemId.includes(searchLower);
      });
      if (hardwareItemsSearched.length > 0) {
        hardwareGroupsSearched[groupName] = hardwareItemsSearched;
      }
    });

    return hardwareGroupsSearched;
  };

  return {
    hardwareGroupsFiltered: hardwareGroups && Object.keys(hardwareGroups).length > 0
      ? applyHardwareSearch(hardwareGroups || {}, hardwareSearch)
      : undefined,
    searchHardware: setHardwareSearch,
  };
}

export function useHardwareSort(hardwareGroups?: HardwareGroupsType) {
  const [hardwareSortField, setHardwareSortField] = useState<IHardwareSortField>({
    field: 'deviceId',
    sort: SortNumericOptions.Ascending,
  });

  useAppTableState('hardware', hardwareSortField, hardwareSortField, setHardwareSortField);

  const { options: numericOptions, getOptionLabel: getNumericOprionLabel } = useSortNumericOptions();

  const applySortToHardwareGroups = (groups: HardwareGroupsType, {
    sort: sortDirection,
  }: IHardwareSortField) => {
    function getSortedGroupNames() {
      const keysWithoutDefault = Object.keys(groups).filter((key) => key !== DEFAULT_HARDWARE_GROUP);
      return [
        DEFAULT_HARDWARE_GROUP,
        ...keysWithoutDefault.sort((a, b) => a.localeCompare(b)),
      ];
    }

    const sortedGroups: HardwareGroupsType = {};
    getSortedGroupNames().forEach((groupName) => {
      const hardwareItems = groups[groupName] ? groups[groupName] : [];

      const monitors = hardwareItems.filter((item) => item.deviceId);
      const idMap = monitors.reduce((acc, item) => {
        acc[item.deviceId] = [...(acc[item.deviceId] || []), item];
        return acc;
      }, {} as Record<string, IHardwareDevice[]>);

      const sortedIds = Object.keys(idMap).sort((a, b) => {
        const aValueOrEmpty = +a || 0;
        const bValueOrEmpty = +b || 0;
        return sortDirection === SortNumericOptions.Ascending ? aValueOrEmpty - bValueOrEmpty : bValueOrEmpty - aValueOrEmpty;
      });

      sortedIds.forEach((id) => {
        const itemsWithSameId = idMap[id];
        const deviceTypeMap = itemsWithSameId.reduce((acc, item) => {
          acc[item.deviceType] = [...(acc[item.deviceType] || []), item];
          return acc;
        }, {} as Record<string, IHardwareDevice[]>);

        const sortedDeviceTypes = Object.keys(deviceTypeMap).sort((a, b) => {
          const aValueOrEmpty = a || '';
          const bValueOrEmpty = b || '';
          return sortDirection === SortNumericOptions.Ascending ? aValueOrEmpty.localeCompare(bValueOrEmpty) : bValueOrEmpty.localeCompare(aValueOrEmpty);
        });

        sortedDeviceTypes.forEach((deviceType) => {
          const itemsWithSameIdAndDeviceType = deviceTypeMap[deviceType];
          const itemsWithSameIdAndDeviceTypeSorted = itemsWithSameIdAndDeviceType.sort((a, b) => {
            const aValueOrEmpty = +a.serialNumber || 0;
            const bValueOrEmpty = +b.serialNumber || 0;
            return sortDirection === SortNumericOptions.Ascending ? aValueOrEmpty - bValueOrEmpty : bValueOrEmpty - aValueOrEmpty;
          });
          sortedGroups[groupName] = [...(sortedGroups[groupName] || []), ...itemsWithSameIdAndDeviceTypeSorted];
        });
      });
      const panels = hardwareItems.filter((item) => !item.deviceId);
      const panelsDeviceNameMap = panels.reduce((acc, item) => {
        acc[item.deviceName] = [...(acc[item.deviceName] || []), item];
        return acc;
      }, {} as Record<string, IHardwareDevice[]>);
      const deviceNamesSorted = Object.keys(panelsDeviceNameMap).sort((a, b) => {
        const aValueOrEmpty = a || '';
        const bValueOrEmpty = b || '';
        return sortDirection === SortNumericOptions.Ascending ? aValueOrEmpty.localeCompare(bValueOrEmpty) : bValueOrEmpty.localeCompare(aValueOrEmpty);
      });
      deviceNamesSorted.forEach((deviceName) => {
        const itemsWithSameDeviceName = panelsDeviceNameMap[deviceName];
        const itemsWithSameDeviceNameSorted = itemsWithSameDeviceName.sort((a, b) => {
          const aValueOrEmpty = +a.serialNumber || 0;
          const bValueOrEmpty = +b.serialNumber || 0;
          return sortDirection === SortNumericOptions.Ascending ? aValueOrEmpty - bValueOrEmpty : bValueOrEmpty - aValueOrEmpty;
        });
        sortedGroups[groupName] = [...(sortedGroups[groupName] || []), ...itemsWithSameDeviceNameSorted];
      });
    });

    return sortedGroups;
  };

  const getHardwareGroupsWithOnlineDevices = (groups: HardwareGroupsType): HardwareGroupsType => {
    const onlineHardwareGroups = Object
      .entries(groups)
      .reduce((res, [groupName, hardwareItems]) => ({
        ...res,
        [groupName]: hardwareItems?.filter((item) => item.isOnline),
      }), {});

    return onlineHardwareGroups;
  };
  const groupsWithOnlineHardwareGroups = hardwareGroups ? getHardwareGroupsWithOnlineDevices(hardwareGroups) : hardwareGroups;
  return {
    isSortedBy: (field: keyof IHardwareDevice) => {
      if (!hardwareSortField) {
        return false;
      }
      return hardwareSortField.field === field;
    },
    getOptions: () => numericOptions,
    getOptionLabel: getNumericOprionLabel,
    getSortedOption: (field: keyof IHardwareDevice) => {
      if (!hardwareSortField) {
        return undefined;
      }
      return hardwareSortField.field === field ? hardwareSortField.sort : undefined;
    },
    buildFieldSortFunction: (field: keyof IHardwareDevice) => (sortOption: string) => {
      const sort = +sortOption as SortNumericOptions;
      setHardwareSortField({ field, sort });
    },
    hardwareGroupsSorted: groupsWithOnlineHardwareGroups && Object.keys(groupsWithOnlineHardwareGroups).length > 0 && hardwareSortField
      ? applySortToHardwareGroups(groupsWithOnlineHardwareGroups, hardwareSortField)
      : groupsWithOnlineHardwareGroups,
  };
}

export enum ConnectDevicePopupResult {
  NavigateToMobileUsers,
  HardwareConnected,
  NoAction
}

async function executeConnectDeviceFlow(mobileUsersCount: number, siteId: string): Promise<ConnectDevicePopupResult> {
  const connected = await showPopup({
    reactNode: <ConnectDevicePopup siteId={siteId} />,
    disableCloseOnEscape: true,
    disableCloseOnOverlay: true,
  });

  if (connected && mobileUsersCount === 0) {
    return await showPopup(<ConnectDeviceAddMobileUsersPopup />)
      ? ConnectDevicePopupResult.NavigateToMobileUsers
      : ConnectDevicePopupResult.HardwareConnected;
  }

  if (connected) {
    await showPopup(<ConnectDeviceSuccessPopup />);
    return ConnectDevicePopupResult.HardwareConnected;
  }

  return ConnectDevicePopupResult.NoAction;
}

export async function connectPanel({
  siteId,
  mobileUsersCount,
  onConnected,
  navigateToMobileUsers,
}: {
  siteId: string,
  mobileUsersCount: number,
  onConnected: () => Promise<void>,
  navigateToMobileUsers: () => void,
}) {
  const result = await executeConnectDeviceFlow(mobileUsersCount, siteId);

  if (result === ConnectDevicePopupResult.NavigateToMobileUsers) {
    navigateToMobileUsers();
  }

  if (result === ConnectDevicePopupResult.HardwareConnected) {
    await onConnected();
  }
}
