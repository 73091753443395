import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as ExpandIcon } from '../../assets/icons/Button-Control icons/Expand icon.svg';
import { ReactComponent as GrowthDecreaseIcon } from '../../assets/icons/minus.svg';
import { ReactComponent as GrowthIncreaseIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as TotalSystemsIcon } from '../../assets/icons/Homescreen icons/Total systems icon.svg';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import { CanNavigateToSitesPermissions } from '../../shared/appUIFramework/appPermisions/permissionsGroups';
import { useAppPermissions } from '../../shared/appUIFramework/appPermisions/useAppPermissions';
import { UserPermission } from '../../shared/appUIFramework/appPermisions/userPermission';
import AppContentToolbar from '../../shared/appUIFramework/components/AppContentToolbar';
import AppPopupMenu from '../../shared/appUIFramework/components/AppPopupMenu';
import { formatPercent } from '../../shared/appUIFramework/formatters/formatPercent';
import AccountActivityBarchart, { BarColor } from './components/AccountActivityBarchart';
import './Home.scss';
import { Costs } from '../../shared/appUIFramework/components/AppCosts';
import { AccountCharges } from '../../shared/appUIFramework/components/AppAccountChargers';
import { getEstimateNextBillDate, useAccountCharges } from '../../shared/appUIFramework/appBackend/useAccountCharges';
import { getActivityGrowth, useAccountActivity } from '../../shared/appUIFramework/appBackend/useAccountActivity';
import { CurrencyCodeString } from '../../shared/appUIFramework/appBackend/useInvoices';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { useTotalUsersPerCompany } from '../../shared/appUIFramework/appBackend/useEntryUsers';
import { useSitesSummary } from '../../shared/appUIFramework/appBackend/useSitesSummary';
import { useCompany } from '../../shared/appUIFramework/appBackend/useCompany';
import { getListOfYearsFromDate } from '../payment/PaymentOverview';
import { isVatRegisteredResellerCompany, useInternalAppsCompanyDetails } from '../../shared/appUIFramework/appBackend/useKeyContact';
import { InstallerStatusMedalsLg, InstallerStatusTranslationKeys } from '../admin/AdminCompanyDetails';

interface IHomeAnalytics {
  estimatedNextBill: number;
  estimatedNextBillDate: Date;
  estimatedNextBillCurrency: CurrencyCodeString;
  estimatedNextBillGrowth: number;
  totalDevices: number;
  totalSystems: number;
  totalSystemsGrowth: number;
}

function useHomeAnalytics(): IHomeAnalytics | undefined {
  const { charges } = useAccountCharges();
  const date = new Date();
  const { activity: sitesActivity } = useAccountActivity(date.getFullYear(), 'sites');
  const { activity: usersActivity } = useAccountActivity(date.getFullYear(), 'users');
  const { usersTotals } = useTotalUsersPerCompany();
  const { sitesSummary } = useSitesSummary({ search: '' });

  if (!charges || !sitesActivity || !usersActivity || !usersTotals || !sitesSummary) {
    return undefined;
  }

  const currentMonth = date.getMonth();

  return {
    estimatedNextBill: charges.monthlyCharge,
    estimatedNextBillCurrency: charges.currencyCode,
    estimatedNextBillGrowth: charges.growth,
    estimatedNextBillDate: getEstimateNextBillDate(),
    totalDevices: usersTotals.totalUsers,
    totalSystems: sitesSummary.totalSites,
    totalSystemsGrowth: getActivityGrowth(sitesActivity, currentMonth),
  };
}

export default function Home() {
  const { t, i18n } = useTranslation();
  const analytics = useHomeAnalytics();
  const showGrowthIncreaseIcon = analytics && analytics.totalSystemsGrowth > 0;
  const [activityMetricType, setActivityMetricType] = useState<'sites' | 'users'>('sites');
  const activityMetricTypes = ['sites', 'users'];
  const activityMetricLabels: Record<string, ReactNode> = {
    sites: <span className="app-selected-secondary-blue">{t('Home_AccountActivity_Systems')}</span>,
    users: <span className="app-selected-secondary-orange">{t('ViewSiteUsers_Title')}</span>,
  };
  const company = useCompany();

  const companyStartUsageYear = new Date(company?.termsAndConditionsAcceptedAt ?? new Date().toString()).getFullYear();
  const currentYear = new Date().getFullYear().toString();
  const accountActivityPerYearOptions = getListOfYearsFromDate(companyStartUsageYear);

  const [year, setYear] = useState(currentYear);
  // noinspection MagicNumberJS
  const { activity } = useAccountActivity(year, activityMetricType);

  const [, setLocation] = useLocation();
  const { hasAnyOfPermissions } = useAppPermissions();
  const goToSystems = () => {
    if (hasAnyOfPermissions(...CanNavigateToSitesPermissions)) {
      setLocation('/systems');
    }
  };

  const { data: iaCompany } = useInternalAppsCompanyDetails();
  const paxtonRewardsInfoIncluded = iaCompany && !isVatRegisteredResellerCompany(iaCompany!);

  if (!analytics || !activity || !iaCompany) {
    return (
      <>
        <AppContentToolbar>
          <span className="app-toolbar-breadcrumb">{t('Navigation_Home')}</span>
        </AppContentToolbar>
        <div className="app-content app-py-36 app-px-30">
          <AppShowLoading showLoading />
        </div>
      </>
    );
  }

  return (
    <>
      <AppContentToolbar>
        <span className="app-toolbar-breadcrumb">{t('Navigation_Home')}</span>
      </AppContentToolbar>
      <div className="app-content app-py-36 app-px-30">
        <div className={`app-home-top-charts ${paxtonRewardsInfoIncluded ? 'app-home-top-charts-paxton-rewars-info-included' : ''}`}>
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewNextEstimatedBill]}>
            <div className="app-home-paper app-home-paper-next-bill">
              <div className="app-home-paper-title">
                {t('Home_EstimatedNextBill')}
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className="app-home-estimated-next-bill app-cursor-pointer"
                onClick={() => {
                  setLocation('/payment/overview');
                }}
              >
                <AccountCharges
                  accountCharges={{
                    currencyCode: analytics.estimatedNextBillCurrency,
                    monthlyCharge: analytics.estimatedNextBill,
                    growth: analytics.estimatedNextBillGrowth,
                  }}
                  showGrowth={false}
                  circleTopKey="Home_EstimatedNextBill_AccountCharges"
                  circleBottomKey="Home_EstimatedNextBill_A_Month"
                />
              </div>
              <div className="app-home-paper-footer">
                <span className="app-home-paper-footer-text">
                  {t('Home_EstimatedNextBill_BillDate')}
                  :&nbsp;
                </span>
                <span className="app-home-paper-footer-text app-home-paper-footer-text-green">
                  {i18n.format(analytics.estimatedNextBillDate, 'SHORT_DATE', i18n.language)}
                </span>
              </div>
            </div>
          </AppPermissionComponentGuard>
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewTotalDevices]}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="app-home-paper app-cursor-pointer"
              onClick={goToSystems}
            >
              <div className="app-home-paper-title">
                {t('Table_TotalAppUsers')}
              </div>
              <div className="app-home-total-devices">
                <Costs value={analytics.totalDevices} />
              </div>
              <div className="app-home-paper-footer" />
            </div>
          </AppPermissionComponentGuard>
          <AppPermissionComponentGuard permissions={[UserPermission.CanViewTotalSites]}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="app-home-paper app-cursor-pointer"
              onClick={goToSystems}
            >
              <div className="app-home-paper-title">
                {t('Home_TotalSystems')}
              </div>
              <div className="app-home-total-systems">
                <div className="app-text-align-center">
                  <TotalSystemsIcon />
                </div>
                <span className="app-home-total-systems-text">{analytics.totalSystems}</span>
              </div>
              <div className="app-home-paper-footer">
                <div
                  tabIndex={0}
                  role="button"
                  className="app-pointer app-home-paper-sites-growth-icon app-d-flex app-align-items-center app-home-paper-footer-growth"
                >
                  {analytics.totalSystemsGrowth !== 0 && (showGrowthIncreaseIcon ? <GrowthIncreaseIcon /> : <GrowthDecreaseIcon />)}
                  <span className="app-home-paper-footer-text">
                    {formatPercent(Math.abs(analytics.totalSystemsGrowth) * 100)}
                  </span>
                </div>
              </div>
            </div>
          </AppPermissionComponentGuard>
          {iaCompany && paxtonRewardsInfoIncluded && (
            <div className="app-home-paper app-home-paper-next-bill">
              <div className="app-d-flex app-justify-content-between">
                <div>
                  <div className="app-font-20 app-weight-600 app-uppercase app-mb-32 app-color-primary-black">{t('PaxtonRewards')}</div>
                  <div className="app-font-20 app-weight-600 app-color-primary-green app-mb-12">
                    {t(`${InstallerStatusTranslationKeys[iaCompany.installerLoyaltyTier]}`)}
                  </div>
                </div>
                <div>
                  {InstallerStatusMedalsLg[iaCompany.installerLoyaltyTier]}
                </div>
              </div>
              <div className="app-font-20 app-lh-28 pp-color-primary-black2">
                {t('ThisQualifiesYourAccountForPercentDiscount', { percent: iaCompany.loyaltyDiscount * 100 })}
              </div>
            </div>
          )}
        </div>
        <div className="app-home-paper app-h-540 app-home-paper-no-paddings">
          <div className="app-home-paper-activity-title app-d-flex app-justify-content-between app-align-items-center">
            <div className="app-home-paper-activity-title-text">
              {t('Home_AccountActivity')}
            </div>
            <div className="app-d-flex app-justify-content-between">
              <AppPopupMenu
                options={activityMetricTypes}
                selectedOption={activityMetricType}
                getOptionLabel={(option) => activityMetricLabels[option]}
                onOptionSelected={(option) => {
                  setActivityMetricType(option);
                }}
                render={() => (
                  <div
                    className="app-home-paper-activity-bar-select app-mr-20 app-d-flex app-align-items-center"
                  >
                    <span className="app-mr-9 app-whitespace-no-wrap">{activityMetricLabels[activityMetricType]}</span>
                    <ExpandIcon />
                  </div>
                )}
              />
              <AppPopupMenu
                options={accountActivityPerYearOptions}
                getOptionLabel={(option) => (
                  <span className={activityMetricType === 'sites'
                    ? 'app-selected-secondary-blue'
                    : 'app-selected-secondary-orange'}
                  >
                    {option}
                  </span>
                )}
                selectedOption={year}
                onOptionSelected={(option) => setYear(option)}
                render={() => (
                  <div
                    className="app-home-paper-activity-bar-select app-d-flex app-align-items-center"
                  >
                    <span className="app-mr-9">{year}</span>
                    <ExpandIcon />
                  </div>
                )}
              />
            </div>
          </div>
          <AccountActivityBarchart
            title={activityMetricType === 'sites' ? t('Home_AccountActivity_Systems') : t('ViewSiteUsers_Title')}
            barColor={activityMetricType === 'sites' ? BarColor.Blue : BarColor.Orange}
            data={activity}
          />
        </div>
      </div>
    </>
  );
}
