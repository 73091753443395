import jsPDF from 'jspdf';
import { useCallback, useRef } from 'react';
import i18n from 'i18next';
import { getCurrencyOptionTranslation, IInvoice, InvoiceStatus } from '../../shared/appUIFramework/appBackend/useInvoices';
import { getInternalAppsCompanyDetails, isSystemManager } from '../../shared/appUIFramework/appBackend/useKeyContact';
import { callAddFont } from './jsPdfCustomFonts';
import PaxtonLogoPng from '../../assets/MyPaxton Logo.png';
import PaxtonBackgroundPng from '../../assets/modal-background.png';
import { DateFormats, formatDate } from '../../shared/appUIFramework/formatters/formatDate';
import { formatMoney } from '../../shared/appUIFramework/formatters/formatMoney';
import { formatPercent } from '../../shared/appUIFramework/formatters/formatPercent';

export function getInvoiceStatusColorClassName(status: InvoiceStatus) {
  return {
    [InvoiceStatus.Unknown]: '',
    [InvoiceStatus.Unpaid]: 'app-color-secondary-yellow',
    [InvoiceStatus.Pending]: 'app-color-secondary-blue',
    [InvoiceStatus.Paid]: 'app-color-primary-green',
    [InvoiceStatus.Failed]: 'app-color-secondary-red',
    [InvoiceStatus.Overdue]: 'app-color-secondary-red',
  }[status];
}

async function getImage(imgUrl: string): Promise<HTMLImageElement> {
  // eslint-disable-next-line no-var
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  const res: Promise<HTMLImageElement> = new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
  img.src = imgUrl;
  return res;
}

const INVOICE_PDF_COLORS = {
  primaryGreen: '#56aa1c',
  textDefault: '#000000',
  rowEven: '#f1f1f2',
  rowOdd: '#ffffff',
};

const FONT_SIZES = {
  normal: 10,
  larger: 12,
  headerTitle: 25,
};

jsPDF.API.events.push(['addFonts', callAddFont]);

let pageLayoutBg: HTMLImageElement;
getImage(PaxtonBackgroundPng).then((img) => {
  pageLayoutBg = img;
});

class InvoicePdfDrawer {
  // eslint-disable-next-line new-cap
  private readonly pdf: jsPDF = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a4',
    putOnlyUsedFonts: true,
  });

  private readonly pagePaddingX = 30;
  private readonly pageContentTitleStartY = 60;
  private readonly pageContentStartX = this.pagePaddingX;
  private readonly pageContentEndX = this.pdf.internal.pageSize.width - this.pagePaddingX;
  private readonly pageContentEndY = this.pdf.internal.pageSize.height;
  private readonly pageContentWidth = this.pageContentEndX - this.pageContentStartX;
  private readonly footerHeight = 60;
  private readonly pageFooterY = this.pdf.internal.pageSize.height - this.footerHeight;
  private readonly pageFooterMargin = 20;
  private readonly greenLineHeight = 8;

  private text(text: string, x: number, y: number, config: {
    align?: 'left' | 'right' | 'center';
    bold?: boolean;
    fontSize: keyof typeof FONT_SIZES;
    color: keyof typeof INVOICE_PDF_COLORS;
  }) {
    this.pdf.setFont(config.bold ? 'Myriad Pro Bold' : 'Myriad Pro Regular', config.bold ? 'bold' : 'normal');
    this.pdf.setFontSize(FONT_SIZES[config.fontSize]);
    this.pdf.setTextColor(INVOICE_PDF_COLORS[config.color]);
    this.pdf.text(text, x, y, { align: config.align || 'left' });
  }

  private drawGreenLine(onTop: boolean) {
    const lineHeight = this.greenLineHeight;
    this.pdf.setDrawColor(INVOICE_PDF_COLORS.primaryGreen);
    this.pdf.setFillColor(INVOICE_PDF_COLORS.primaryGreen);
    const [x, y] = onTop
      ? [0, 0]
      : [0, this.pdf.internal.pageSize.height - lineHeight];

    this.pdf.rect(x, y, this.pdf.internal.pageSize.width, lineHeight, 'F');
  }

  private async drawHeader() {
    const logoImg = await getImage(PaxtonLogoPng);
    this.pdf.addImage(logoImg, 'PNG', this.pageContentStartX, this.pageContentTitleStartY - 15, logoImg.width / 4, logoImg.height / 4);
    this.text(i18n.t('Invoice').toUpperCase(), this.pageContentEndX, this.pageContentTitleStartY,
      {
        fontSize: 'headerTitle',
        color: 'textDefault',
        align: 'right',
      });
  }

  private async drawInvoiceInfo(invoice: IInvoice, branchOfficeId: number) {
    const blockStart = this.pageContentTitleStartY + 50;
    const marginAfterLine = 2;
    const firstLineHeight = FONT_SIZES.larger;
    const contentLineHeight = FONT_SIZES.normal;
    const getYFromIndex = (index: number) => blockStart + firstLineHeight + marginAfterLine + index * (contentLineHeight + marginAfterLine);

    // draw invoice info left
    this.text(i18n.t('InvoiceTo').toUpperCase(), this.pageContentStartX, blockStart, {
      fontSize: 'larger',
      color: 'primaryGreen',
      bold: true,
    });

    const invoiceInfoData = [
      invoice.invoiceAddress.companyName,
      invoice.invoiceAddress.address1,
      invoice.invoiceAddress.address2,
      invoice.invoiceAddress.townCity,
      invoice.invoiceAddress.postcode,
    ];

    invoiceInfoData.forEach((data, index) => {
      this.text(data, this.pageContentStartX, getYFromIndex(index), {
        fontSize: 'normal',
        color: 'textDefault',
      });
    });

    // draw invoice info right
    const invoiceInfoDataRight = [
      { label: i18n.t('InvoiceNo'), value: invoice.invoiceId },

      { label: i18n.t('CustomerVatNumber'), value: invoice.customerVatNumber },
      { label: i18n.t('InvoiceDate'), value: formatDate(invoice.invoiceDate, DateFormats.LongDate, i18n), bold: true },
      { label: i18n.t('AccountReference'), value: branchOfficeId },
    ];
    invoiceInfoDataRight
      .filter((p) => p.value)
      .forEach((data, index) => {
        this.text(`${data.label}: `, this.pageContentEndX - 220, getYFromIndex(index), {
          fontSize: 'normal',
          color: 'textDefault',
        });
        this.text(data.value?.toString() || '', this.pageContentEndX, getYFromIndex(index),
          {
            fontSize: 'normal',
            color: 'textDefault',
            align: 'right',
            bold: data.bold,
          });
      });

    return getYFromIndex(invoiceInfoData.length);
  }

  private isNewPageRequired(y: number, height: number): boolean {
    return y + height > this.pageFooterY - this.pageFooterMargin;
  }

  private drawFooter() {
    const blockStart = this.pageFooterY;
    const textHeight = FONT_SIZES.normal;
    const marginAfterLine = 2;
    const lineHeight = textHeight + marginAfterLine;

    const data = [
      i18n.t('PaxtonAccessLtd'),
      i18n.t('PaxtonAccessTel'),
      i18n.t('PaxtonVatNumber'),
    ];

    const getYFromIndex = (index: number) => blockStart + index * lineHeight;

    const drawText = (text: string, index: number) => {
      this.text(text, this.pdf.internal.pageSize.getWidth() / 2, getYFromIndex(index), {
        fontSize: 'normal',
        color: 'textDefault',
        align: 'center',
      });
    };

    data.forEach(drawText);
  }

  private async drawPageLayout() {
    this.drawGreenLine(true);
    this.drawGreenLine(false);

    // should be loaded, the bg image load starts with page load, check added just in case
    // when trying to use await for get bg image in place the layout sometimes is not drawn (detected on page count 6 and 19)
    if (pageLayoutBg) {
      const height = pageLayoutBg.height / 2;
      this.pdf.addImage(pageLayoutBg, 'PNG', 0, this.pdf.internal.pageSize.height - height - this.greenLineHeight, pageLayoutBg.width / 2, height);
    }

    this.drawFooter();
  }

  private drawPageNumbers() {
    const pageCount = this.pdf.getNumberOfPages();
    for (let pageNumber = 1; pageNumber <= pageCount; pageNumber += 1) {
      this.pdf.setPage(pageNumber);
      const text = `${pageNumber} of ${pageCount}`;
      this.text(text, this.pageContentEndX, this.pageContentEndY - 20, {
        fontSize: 'normal',
        color: 'primaryGreen',
        align: 'right',
      });
    }
  }

  private async addPage() {
    this.pdf.addPage();
    this.pdf.setPage(this.pdf.getNumberOfPages());
    await this.drawPageLayout();
  }

  private async drawInvoiceItems(invoice: IInvoice, blockStart: number, startIndex: number = 0): Promise<number> {
    const lineHeight = 12;
    const tableRowPaddingX = 12;
    const tableRowPaddingY = 10;
    const getTableRowHeight = (lines: number = 1) => lineHeight * lines + tableRowPaddingY * 2;
    const getLeftColX = (colX: number, lineIndexInsideRow = 0) => this.pageContentStartX + colX + tableRowPaddingX + lineIndexInsideRow * tableRowPaddingX;
    const getRightColX = (colX: number) => this.pageContentEndX - colX - tableRowPaddingX;

    const getColTextY = (row: number, lineIndexInsideRow = 0) => {
      const firstRowHeight = getTableRowHeight(1);
      const prevRowsHeight = row > 0 ? (row - 1) * getTableRowHeight(2) + firstRowHeight : 0;
      return blockStart + prevRowsHeight + tableRowPaddingY + lineIndexInsideRow * lineHeight;
    };

    const drawColText = (text: string, row: number, colIndex: number, lineIndexInsideRow: number, bold?: boolean) => {
      const x = [0, 225, 125, 45][colIndex];
      const getX = colIndex === 0 ? getLeftColX : getRightColX;
      const y = getColTextY(row, lineIndexInsideRow);
      this.text(text, getX(x, lineIndexInsideRow), y, {
        fontSize: 'normal',
        color: 'textDefault',
        bold,
      });

      return y;
    };

    // draw header
    this.pdf.setDrawColor(INVOICE_PDF_COLORS.primaryGreen);
    this.pdf.setFillColor(INVOICE_PDF_COLORS.primaryGreen);
    this.pdf.roundedRect(this.pageContentStartX, getColTextY(0) - tableRowPaddingY * 2, this.pageContentWidth, getTableRowHeight(1), 3, 3, 'F');
    this.pdf.rect(this.pageContentStartX, getColTextY(0) - tableRowPaddingY * 2 + 3, this.pageContentWidth, getTableRowHeight(1) - 3, 'F'); // hide bottom border radius
    [
      i18n.t('Description').toUpperCase(),
      i18n.t('Quantity').toUpperCase(),
      `${i18n.t('UnitPrice')} (${getCurrencyOptionTranslation(invoice.currencyCodeId)})`,
      `${i18n.t('Total')} (${getCurrencyOptionTranslation(invoice.currencyCodeId)})`,
    ].forEach((text, index) => {
      drawColText(text, 0, index, 0, true);
    });

    let lastRowY = 0;
    let lastRowHeight = 0;

    let row = 1;
    for (let index = startIndex; index < invoice.billableUnits.length; index += 1) {
      const billableUnit = invoice.billableUnits[index];
      const rowHeight = getTableRowHeight(2);
      const rowY = getColTextY(row) - (row === 0 ? tableRowPaddingY : tableRowPaddingY * 2);
      lastRowY = rowY;
      lastRowHeight = rowHeight;
      if (this.isNewPageRequired(rowY, rowHeight)) {
        // eslint-disable-next-line no-await-in-loop
        await this.addPage();
        return this.drawInvoiceItems(invoice, this.pageContentTitleStartY - 15, index);
      }

      // draw row background
      const isEven = index % 2 === 0;
      const bgColor = isEven ? INVOICE_PDF_COLORS.rowEven : INVOICE_PDF_COLORS.rowOdd;
      this.pdf.setDrawColor(bgColor);
      this.pdf.setFillColor(bgColor);
      this.pdf.rect(this.pageContentStartX, rowY, this.pageContentWidth, rowHeight, 'F');

      // draw row text
      drawColText(`${billableUnit.description}`, row, 0, 0, true);
      drawColText('Entry App Users', row, 0, 1);
      drawColText(billableUnit.numberOfUnits.toString(), row, 1, 1);
      drawColText(formatMoney(invoice.currencyCodeId, billableUnit.retailPrice), row, 2, 1);
      const total = billableUnit.numberOfUnits * billableUnit.retailPrice;
      drawColText(formatMoney(invoice.currencyCodeId, total), row, 3, 1);
      row += 1;
    }

    return lastRowY + lastRowHeight;
  }

  private async drawInvoiceTotals(invoice: IInvoice, blockStart: number): Promise<number> {
    const marginAfterLine = 2;
    const contentLineHeight = FONT_SIZES.normal;
    const blockContentWidth = 80;
    const blockPaddingX = 5;
    const blockWidth = blockContentWidth + blockPaddingX * 2;
    const totalsLineExtraPadding = 10;
    const getYFromIndex = (index: number, isTotalLine?: boolean) => {
      const allLinesHeight = index * (contentLineHeight + marginAfterLine);
      const totalLineExtra = isTotalLine ? totalsLineExtraPadding : 0;
      return blockStart + allLinesHeight + totalLineExtra;
    };

    const drawText = (text: string, left: boolean, index: number, isTotalLine?: boolean) => {
      this.text(text,
        left
          ? this.pageContentEndX - blockWidth + blockPaddingX
          : this.pageContentEndX - blockPaddingX,
        getYFromIndex(index, isTotalLine),
        {
          fontSize: 'normal',
          color: 'textDefault',
          align: left ? 'left' : 'right',
          bold: isTotalLine,
        });
    };

    const values = [
      { label: i18n.t('SubTotal'), value: formatMoney(invoice.currencyCodeId, invoice.Totals.Subtotal) },
      { label: `${i18n.t('Vat')} @${formatPercent(invoice.vatPercentage * 100)}`, value: formatMoney(invoice.currencyCodeId, invoice.Totals.Vat) },
      { label: i18n.t('Currency'), value: getCurrencyOptionTranslation(invoice.currencyCodeId) },
      { label: i18n.t('TotaL'), value: formatMoney(invoice.currencyCodeId, invoice.Totals.Total), isTotalLine: true },
    ].filter((p) => !!p.value);

    const totalHeight = (values.length - 1) * (contentLineHeight + marginAfterLine) + totalsLineExtraPadding * 2;
    if (this.isNewPageRequired(blockStart, totalHeight)) {
      await this.addPage();
      return this.drawInvoiceTotals(invoice, this.pageContentTitleStartY - 15);
    }

    // draw background
    const rectX = this.pageContentEndX - blockWidth - blockPaddingX;
    const rectY = getYFromIndex(values.length - 1, true) - totalsLineExtraPadding - marginAfterLine;
    const rectHeight = contentLineHeight + totalsLineExtraPadding + marginAfterLine;
    const rectWidth = blockWidth + blockPaddingX;
    this.pdf.setDrawColor(INVOICE_PDF_COLORS.primaryGreen);
    this.pdf.setFillColor(INVOICE_PDF_COLORS.primaryGreen);
    this.pdf.roundedRect(rectX, rectY, rectWidth, rectHeight, 3, 3, 'F');
    this.pdf.rect(rectX, rectY, rectWidth, rectHeight - 3, 'F');

    // draw content
    values
      .forEach((data, index) => {
        drawText(`${data.label}: `, true, index, data.isTotalLine);
        drawText(data.value, false, index, data.isTotalLine);
      });

    return blockStart + totalHeight;
  }

  private async drawPaymentHelperInfo(invoice: IInvoice, isSystemManagerArg: boolean, blockStart: number): Promise<number> {
    const lineHeight = FONT_SIZES.normal;
    const marginAfterLine = 2;

    const getYFromIndex = (index: number) => blockStart + index * (lineHeight + marginAfterLine);

    const data = [
      isSystemManagerArg ? i18n.t('PaymentTermsInAdvance') : i18n.t('PaymentTermsDays', { days: 30 }),
      i18n.t('YouCanPayInvoice'),
    ];

    const totalHeight = data.length * (lineHeight + marginAfterLine);
    if (this.isNewPageRequired(blockStart, totalHeight)) {
      this.addPage();
      return this.drawPaymentHelperInfo(invoice, isSystemManagerArg, this.pageContentTitleStartY - 15);
    }

    const drawText = (text: string, index: number) => {
      this.text(text, this.pageContentStartX, getYFromIndex(index), {
        fontSize: 'normal',
        color: 'textDefault',
        bold: true,
      });
    };

    data.forEach(drawText);

    return blockStart + totalHeight;
  }

  async downloadInvoice(invoice: IInvoice) {
    this.drawPageLayout();
    await this.drawHeader();
    const internalAppsCompany = await getInternalAppsCompanyDetails(invoice.customerReference);
    const invoiceInfoBlockEnd = await this.drawInvoiceInfo(invoice, internalAppsCompany.branchOfficeId);
    const invoiceItemsBlockEnd = await this.drawInvoiceItems(invoice, invoiceInfoBlockEnd + 30);
    const invoiceTotalsBlockEnd = await this.drawInvoiceTotals(invoice, invoiceItemsBlockEnd + 20);
    await this.drawPaymentHelperInfo(invoice, isSystemManager(internalAppsCompany), invoiceTotalsBlockEnd + 40);
    this.drawPageNumbers();

    // save to file
    const generationDate = new Date().toISOString().split('T')[0];
    this.pdf.save(`${invoice.invoiceId}-${generationDate}.pdf`);
  }
}

export async function downloadInvoice(invoice: IInvoice) {
  const drawer = new InvoicePdfDrawer();
  await drawer.downloadInvoice(invoice);
}

export function useFetchAndDownloadInvoice(getInvoice: (invoiceId: number) => Promise<IInvoice | null>) {
  const downloadStarted = useRef(false);

  return useCallback(async (invoiceId: number) => {
    try {
      if (downloadStarted.current) {
        return;
      }
      downloadStarted.current = true;
      const invoice = await getInvoice(invoiceId);
      if (!invoice) {
        return;
      }
      await downloadInvoice(invoice);
    } finally {
      downloadStarted.current = false;
    }
  }, [getInvoice]);
}

export function useDownloadInvoice(invoice?: IInvoice) {
  const download = useFetchAndDownloadInvoice(() => Promise.resolve(invoice!));
  return useCallback(async () => {
    if (invoice) {
      await download(invoice.invoiceId);
    }
  }, [download, invoice]);
}
