import { CurrencyCodeString, getTotalUnitsPrice, IDiscount } from './useInvoices';
import { Urls } from '../../backend/urls';
import { useClaims } from '../../backend/auth/claims';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { getActivityGrowth, useAccountActivity } from './useAccountActivity';
import { httpGetJsonOrDefault } from '../../backend/http/http';
import { useTotalUsersPerCompany } from './useEntryUsers';
import { roundAsCurrencyOnUI, roundToDecimalPlaces } from '../../jsUtils/roundToDecimals';
import { CURRENCY_DECIMALS_AMOUNT } from '../constants/constants';

export interface ICustomerPricing {
    currency: CurrencyCodeString;
    value: number;
    rrp: number;
}

interface IInternalAppsPricingItem {
    salesCode: string;
    currencyCode: CurrencyCodeString;
    currencySymbol: string;
    currencyMultiplier: number;
    rrp: number;
    price: number;
}

interface IInternalAppsCustomerPricing {
    items: IInternalAppsPricingItem[];
    discounts: IDiscount[];
}

function getCostsFromPricing({
  price,
  currencyCode,
  currencyMultiplier,
  rrp,
}: IInternalAppsPricingItem): ICustomerPricing {
  return {
    currency: currencyCode,
    value: roundToDecimalPlaces(price * currencyMultiplier, CURRENCY_DECIMALS_AMOUNT),
    rrp: roundToDecimalPlaces(rrp * currencyMultiplier, CURRENCY_DECIMALS_AMOUNT),
  };
}

const mobileUsersSalesCode = '337-001';

function getPricingPlaceholder() {
  return {
    isDataAPlaceholder: (data: IInternalAppsCustomerPricing | undefined) => data && data.items.length === 0 && data.discounts.length === 0,
    placeholder: {
      items: [],
      discounts: [],
    },
  };
}

export function useCustomerPricing(): { costs: ICustomerPricing | undefined } {
  const { customerReference } = useClaims();
  const url = Urls.InternalAppsCustomerPricing(customerReference);

  const { isDataAPlaceholder, placeholder } = getPricingPlaceholder();
  const { data } = useSWRAndHandleErrors<IInternalAppsCustomerPricing>(url, () => httpGetJsonOrDefault(url, placeholder));

  const pricing = data?.items.find((p) => p.salesCode.startsWith(mobileUsersSalesCode));

  if (!data) {
    return { costs: undefined };
  }

  return isDataAPlaceholder(data) || !pricing
    ? {
      costs: {
        currency: CurrencyCodeString.GBP,
        value: Number.NaN,
        rrp: Number.NaN,
      },
    } : {
      costs: pricing && getCostsFromPricing(pricing),
    };
}

export interface IAccountCharges {
    growth: number;
    monthlyCharge: number;
    currencyCode: CurrencyCodeString;
}

export function getEstimateNextBillDate() {
  const date = new Date();
  const firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  return firstDayOfNextMonth;
}

export function useAccountCharges(): { charges: IAccountCharges | undefined } {
  const { costs } = useCustomerPricing();
  const date = new Date();
  const { activity } = useAccountActivity(date.getFullYear(), 'users');
  const { usersTotals } = useTotalUsersPerCompany();

  if (!costs || !activity || !usersTotals) {
    return { charges: undefined };
  }

  const currentMonth = date.getMonth();
  return {
    charges: {
      growth: getActivityGrowth(activity, currentMonth),
      monthlyCharge: getTotalUnitsPrice(roundAsCurrencyOnUI(costs.value), usersTotals.totalUsers),
      currencyCode: costs.currency,
    },
  };
}
