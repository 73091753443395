export interface ICompanyActivity {
    year: number;
    month: number;
    sitesCount: number;
    activeUsersCount: number;
}

export enum CompanyRegion {
    UnitedKingdom = 'UnitedKingdom',
    Ireland = 'Ireland',
    UnitedStates = 'UnitedStates',
    Netherlands = 'Netherlands',
    Belgium = 'Belgium',
    Luxembourg = 'Luxembourg',
    Germany = 'Germany',
    France = 'France',
    SouthAfrica = 'SouthAfrica',
    Canada = 'Canada',
    Sweden = 'Sweden',
    Norway = 'Norway',
    Denmark = 'Denmark',
}

export interface ICompanyBillingAddress {
  address1: string;
  address2: string;
  city: string;
  countryCode:string;
  state: string;
  postCode: string;
}

export interface ICompanyInfo {
    id: string;
    companyName: string;
    companyLogo: string;
    companyActivities: ICompanyActivity[];
    createDate: string;
    termsAndConditionsAcceptedAt?: string;
    termsAndConditionsAcceptedBy?: string;
    entryRegion: CompanyRegion;
    taxCertificateBlobUri: string;
    taxCertificateFileName: string;
    taxCertificateUploadedAt: string;
    vatNumber: string;
}
