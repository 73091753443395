import { IHardwareDevice } from '../models/IHardware';

enum DeviceType {
  Mobile = 'mobile'
}

function isDeviceIdValid(device: IHardwareDevice) {
  return !!device.deviceId
    && device.deviceId.trim().length > 0
    && Number(device.deviceId) !== 0;
}

function isSameIdAndGroup(device: IHardwareDevice, callId?: string, group?: string) {
  const trimmedCallId = (callId || '').trim();
  const trimmedGroup = (group || '').trim();

  const res = (device.group || '') === trimmedGroup
    && device.deviceId === trimmedCallId
    && isDeviceIdValid(device);

  return res;
}

function isSameNameAndGroup(device: IHardwareDevice, callName?: string, group?: string) {
  const trimmedCallName = (callName || '').trim();
  const trimmedGroup = (group || '').trim();

  return device.group === trimmedGroup
    && device.deviceName === trimmedCallName
    && isDeviceIdValid(device);
}

function isDeviceNotAMobile(device: IHardwareDevice) {
  return !!device.deviceType && device.deviceType.toLowerCase() !== DeviceType.Mobile;
}

export function tryPopulateCallNameFromDeviceWithSameIdAndGroup(devices: IHardwareDevice[], callId: string, group: string, setName: (newName: string) => void) {
  const device = devices.find((p) => isSameIdAndGroup(p, callId, group));
  if (device) {
    setName(device.deviceName);
  }
}

export function getCallName(devices: IHardwareDevice[], callId: string, group: string, callName: string) {
  const monitor = devices.find((p) => isDeviceNotAMobile(p) && isSameIdAndGroup(p, callId, group));
  return monitor ? monitor.deviceName : callName;
}

export function isDisplayNameEditable(devices: IHardwareDevice[], isSiteBound: boolean, callId: string, group: string) {
  if (!callId) {
    return true;
  }

  const notMobileDevices = devices.filter((p) => isDeviceNotAMobile(p) && isSameIdAndGroup(p, callId, group));
  const noOtherNonMobileDevices = notMobileDevices.length === 0;
  const noInformationAboutDevices = !isSiteBound;
  return noInformationAboutDevices || noOtherNonMobileDevices;
}

export function getAmountOfUsersWithSameIdInTheGroup(devices: IHardwareDevice[], callId: string, group: string) {
  return devices.filter((p) => isSameIdAndGroup(p, callId, group)).length;
}

export function isAnotherUserWithSameCallIdAndGroup(devices: IHardwareDevice[], callId: string, group: string, deviceNotAdded: boolean) {
  const limit = deviceNotAdded ? 0 : 1;
  return getAmountOfUsersWithSameIdInTheGroup(devices, callId, group) > limit;
}

export function isAnyDeviceWithSameNameAndGroupButOtherId(devices: IHardwareDevice[], callId: string, callName: string, group: string, deviceNotAdded: boolean) {
  const devicesInGroupWithSameNameAndAnotherId = devices.filter((p) => isSameNameAndGroup(p, callName, group)
    && p.deviceId !== callId);

  return deviceNotAdded
    ? devicesInGroupWithSameNameAndAnotherId.length > 0
    : devicesInGroupWithSameNameAndAnotherId.length > 1;
}

export function getEntryUserDeviceGroups(devices: IHardwareDevice[] | undefined): string[] {
  return (devices || []).map((contact) => contact.group)
    .filter((group, index, self) => {
      const theGroupIsNotEmpty = !!group;
      const theGroupIsNotDuplicate = self.indexOf(group) === index;

      return theGroupIsNotEmpty && theGroupIsNotDuplicate;
    }).sort((a, b) => a.localeCompare(b));
}
