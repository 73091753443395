import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as MenuNavigationIcon } from '../../../assets/icons/mobile/Menu Navigation icon.svg';
import PaxtonLogo from '../../../assets/paxton-logo.svg';
import { ReactComponent as NextLevelIcon } from '../../../assets/icons/Button-Control icons/Next level icon.svg';
import { ReactComponent as PasswordIcon } from '../../../assets/icons/Menu option icons/Password icon.svg';
import { ReactComponent as SupportIcon } from '../../../assets/icons/Support icon.svg';
import HelpIcon from '../../../assets/icons/Navigation ribbon icons/Help Icon.svg';
import NotificationsIcon from '../../../assets/icons/Navigation ribbon icons/Notification icon.svg';
import { ReactComponent as ToolbarProfileIcon } from '../../../assets/Toolbar-Profile.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/icons/Text field icons/Name Icon V2.svg';
import { B2CLangCode } from '../constants/B2CLangCode';
import { useBooleanState } from '../hooks/useBooleanState';
import { useLanguagePopupMenu } from '../hooks/useLanguagePopupMenu';
import { showPopup } from '../popup/AppPopup';
import AppHelpPopup from './AppHelpPopup';
import AppNotificationsDropdown from './AppNotifications/AppNotificationsDropdown';
import AppOutsideListener from './AppOutsideListener';
import AppPopupMenu from './AppPopupMenu';
import { useAppNotificationsUnreadCount } from '../appBackend/useAppNotifications';
import { debounce } from '../../jsUtils/debounce';
import AppDoYouWantToChangePasswordPopup from './AppDoYouWantToChangePasswordPopup';
import callIfEnterKeyPressed from '../../jsUtils/callIfEnterKeyPressed';
import AppInviteSupportPopup from './AppInviteSupportPopup';
import { useAppLocalisation } from '../../i18n/config';
import { goToChangePassword } from '../../backend/auth/passwordChangeUtils';

export default function AppContentToolbar({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const { lang: language, changeLanguage } = useAppLocalisation();
  const [, setLocation] = useLocation();
  const { languageOptions, getLanguageOptionLabel } = useLanguagePopupMenu();
  const { value: notificationsVisible, toggle: toggleNotificationsVisible, reset: hideNotificationVisible } = useBooleanState(false);
  const [languagesPopUpWidth, setLanguagesPopUpWidth] = useState(0);
  const [parentPopUpWidth, setParentPopUpWidth] = useState(0);
  const [vieportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const resizeHandler: ResizeObserverCallback = (entries) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        setViewportWidth(window.innerWidth);
      });
    };

    const resizeObserver = new ResizeObserver(debounce(resizeHandler, 100));
    resizeObserver.observe(document.body);
    return resizeObserver.disconnect.bind(resizeObserver);
  }, []);

  async function showHelpPopup() {
    await showPopup(<AppHelpPopup />);
  }

  const toolbarMenu: {
    [key: string]: {
      label: ReactNode,
      onSelected: () => void,
    }
  } = {
    profile: {
      label: (
        <div className="app-d-flex app-align-items-center">
          <ProfileIcon className="app-mr-9 app-w-33" />
          <span>{t('Toolbar_Profile')}</span>
        </div>
      ),
      onSelected: () => {
        setLocation('/profile');
      },
    },
    language: {
      label: (
        <AppPopupMenu
          options={languageOptions}
          selectedOption={language}
          onOptionSelected={(lang) => {
            changeLanguage(lang);
          }}
          getOptionLabel={getLanguageOptionLabel as (option: string) => string}
          render={() => (
            <div className="app-d-flex app-align-items-center">
              {getLanguageOptionLabel(language as B2CLangCode)}
              <NextLevelIcon />
            </div>
          )}
          onItemsWidthChange={setLanguagesPopUpWidth}
        />
      ),
      onSelected: () => true,
    },
    inviteSupport: {
      label: (
        <div className="app-d-flex app-align-items-center">
          <SupportIcon className="app-mr-9 app-w-33" />
          <span>{t('InviteSupport')}</span>
        </div>
      ),
      onSelected: async () => {
        await showPopup(<AppInviteSupportPopup />);
      },
    },
    changePassword: {
      label: (
        <div className="app-d-flex app-align-items-center">
          <PasswordIcon className="app-mr-9" />
          <span>{t('Label_Password')}</span>
        </div>
      ),
      onSelected: async () => {
        const shouldChange = await showPopup(<AppDoYouWantToChangePasswordPopup />);
        if (shouldChange) {
          await goToChangePassword(language);
        }
      },
    },
  };
  const toolbarMenuOptions = Object.keys(toolbarMenu);

  const { unreadCount } = useAppNotificationsUnreadCount();

  return (
    <>
      <div
        className="app-content-toolbar app-d-flex app-align-items-center"
      >
        <div className="app-navigation-mobile app-flex-1">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="app-mt-5" htmlFor="menu-visibility-handler-checkbox">
            <MenuNavigationIcon />
          </label>
          <img
            src={PaxtonLogo}
            alt="Paxton Logo"
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div className="app-toolbar-breadcrumbs">
          {children}
        </div>
        <div
          className="app-d-flex app-align-items-center app-flex-1 app-justify-content-end"
        >
          <AppOutsideListener onClick={hideNotificationVisible}>
            <div
              className="app-mr-20 app-h-30 app-w-30 app-icon-with-badge app-hover-pointer"
              role="button"
              tabIndex={0}
              onKeyDown={callIfEnterKeyPressed(toggleNotificationsVisible)}
              onClick={toggleNotificationsVisible}
            >
              <img
                src={NotificationsIcon}
                alt="Notifications"
              />
              {unreadCount > 0 && (
              <div className="app-badge">
                {unreadCount}
              </div>
              )}
            </div>
            { notificationsVisible && <AppNotificationsDropdown /> }
          </AppOutsideListener>
          <div
            className="app-mr-20 app-h-30 app-w-30 app-help-btn app-hover-pointer"
            role="button"
            tabIndex={0}
            onKeyDown={callIfEnterKeyPressed(showHelpPopup)}
            onClick={showHelpPopup}
          >
            <img
              src={HelpIcon}
              alt="Help"
            />
          </div>
          <div className="app-toolbar-action-logo app-h-30 app-w-30">
            <AppPopupMenu
              options={toolbarMenuOptions}
              optionsContainerClassName={`${language}${parentPopUpWidth + languagesPopUpWidth >= vieportWidth ? ' app-toolbar-popup-lock-left' : ''}`}
              getOptionLabel={(option) => toolbarMenu[option].label}
              onOptionSelected={(option) => toolbarMenu[option]?.onSelected()}
              render={() => <ToolbarProfileIcon />}
              onItemsWidthChange={setParentPopUpWidth}
            />
          </div>
        </div>
      </div>
      <div className="app-toolbar-breadcrumbs-mobile">
        {children}
      </div>
    </>
  );
}
