import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'wouter';
import { ReactComponent as EmailIcon } from '../../assets/icons/Text field icons/Email  icon.svg';
import {
  useUnsavedPopup,
} from '../../shared/appUIFramework/appForm/useAppUnsavedChangesLocation';
import { EMAIL_PATTERN } from '../../shared/appUIFramework/constants/constants';
import SignupLayout from './components/SignupLayout';
import { useSignUpState } from './hooks/useSignupState';
import './SignVatInstallerForm.scss';
import { isEmailWhitelisted } from './signup.api';
import { showPopup } from '../../shared/appUIFramework/popup/AppPopup';
import EmailNotWhitelistedPopup from './components/EmailNotWhitelistedPopup';
import { handleHttpRequestError } from '../../shared/appUIFramework/appBackend/errorHandling/useSWRAndHandleErrors';
import { AccountType, getAccountTypeFromEmail, isAccountExistsInAD } from '../../shared/appUIFramework/appBackend/useKeyContact';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';

export default function SignUpVatInstallerEmail() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { getValue, setValue } = useSignUpState();
  const {
    register, handleSubmit, getValues, formState: {
      errors, isDirty, isSubmitting,
    },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      Email: getValue().Email,
    },
  });

  const saveFormAndGoToPersonalInfo = async () => {
    const email = getValues().Email;
    try {
      const whiteListed = await isEmailWhitelisted(email);

      if (!whiteListed) {
        await showPopup(<EmailNotWhitelistedPopup />);
        return;
      }

      setValue(getValues());
      setLocation('/signup/vat-installer/personal-info');
    } catch (e) {
      await handleHttpRequestError(e);
    }
  };

  const goToSignUpVariant = () => {
    setLocation('/signup/variant');
  };

  useUnsavedPopup('SignUpInstallerEmail', isDirty);

  return (
    <SignupLayout>
      <div className="app-font-28 app-weight-600 app-mb-26">
        {t('SignUp')}
      </div>
      <form onSubmit={handleSubmit(saveFormAndGoToPersonalInfo)} className="app-d-flex app-flex-column app-gap-23 app-mw-411 app-w-411">
        <AppShowLoading showLoading={isSubmitting} inline>
          <div className="app-signup-input-container">
            <EmailIcon className="app-signup-input-prefix" />
            <input
              {...register('Email', {
                pattern: EMAIL_PATTERN,
                required: true,
                validate: {
                  custom: async (email?: string) => {
                    if (!email) {
                      return true;
                    }

                    if (EMAIL_PATTERN.test(email) === false) {
                      return true;
                    }

                    const isEmailExistsInAd = await isAccountExistsInAD(email);
                    const accountType = await getAccountTypeFromEmail(email);

                    const branchIdCheckFailed = accountType === AccountType.RegisteredInstaller && !isEmailExistsInAd;
                    if (branchIdCheckFailed) {
                      return t('AnAccountAlreadyExistsPleaseContactAdmin') as string;
                    }

                    if (accountType === AccountType.Installer) {
                      return t('SignUpAsRegisteredInstallerThroughPaxtonInstallerApp') as string;
                    }

                    if (accountType === AccountType.SystemManager && !isEmailExistsInAd) {
                      return undefined;
                    }

                    if (accountType !== AccountType.Unkonwn || isEmailExistsInAd) {
                      return t('TheEmailAddressAlreadyExistsPleaseLogin') as string;
                    }

                    return true;
                  },
                },
              })}
              data-invalid={errors.Email ? 'true' : 'false'}
              placeholder={t('Form_Email')}
              type="text"
              className="app-form-input app-signup-input"
            />
          </div>
          {errors?.Email && (
            <div className="app-signup-input-error app-mt--20">
              {errors.Email.type === 'pattern' && t('SiteAdd_EmailInvalid')}
              {errors.Email.type === 'required' && t('Form_FieldRequired')}
              {errors.Email.type === 'custom' && errors.Email.message}
            </div>
          )}
        </AppShowLoading>
        <button className="app-primary-button app-text-transform-none app-signup-button-paddings" type="submit">
          {t('SignUp')}
        </button>
      </form>
      <div className="app-d-flex app-w-100pcnt app-justify-content-center app-mt-22">
        <button className="app-link-primary-button" type="button" onClick={goToSignUpVariant}>
          {t('Back')}
        </button>
      </div>
    </SignupLayout>
  );
}
