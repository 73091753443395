import { useTranslation } from 'react-i18next';
import { closePopup } from '../../../shared/appUIFramework/popup/AppPopup';
import { AppPopupLayout } from '../../../shared/appUIFramework/popup/AppPopupLayout';
import { ReactComponent as ProcessingPayment } from '../../../assets/ProcessingPaymentIcon.svg';
import { useIsAccountBlocked } from '../../../shared/appUIFramework/appBackend/useAccountInfo';

export default function ProcessingManualPaymentPopup() {
  const { t } = useTranslation();
  const isBlocked = useIsAccountBlocked();

  return (
    <AppPopupLayout
      extraClass="app-popup-connect"
      onHeaderClose={closePopup}
      popupBody={(
        <>
          <ProcessingPayment />
          <span className="app-font-20 app-uppercase app-weight-600">
            {t('Popup_ProcessingPayment')}
          </span>
          <span className="app-font-20 app-text-align-center">
            {t('Popup_YourPaymentIsBeingProcessed')}
          </span>
          {isBlocked && (
            <span className="app-font-20">
              {t('Popup_UponCompletionOfPaymentYouWillNeedToContact')}
            </span>
          )}
          <span />
        </>
      )}
    />
  );
}
