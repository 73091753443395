import { RefObject } from 'react';
import { useClaims } from '../../backend/auth/claims';
import { Urls } from '../../backend/urls';
import { getUrlWithQueryParams, httpGetJson } from '../../backend/http/http';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { IGridQueryParams } from '../../../routes/systems/models/IGridQueryParams';
import {
  CurrencyCodeString,
  getCurrencyCodeStringFromId,
  getTotalUnitsPrice,
  IBillableUnit,
  IInvoice,
} from './useInvoices';
import { useCompany } from './useCompany';
import { SortStringOptions } from '../hooks/useSortStringOptions';
import { useSitesSummary } from './useSitesSummary';
import { IInfiniteWithPaginationModel, useInfiniteWithPagination } from '../hooks/useInfiniteWithPagination';
import { roundAsCurrencyOnUI } from '../../jsUtils/roundToDecimals';
import { ICustomerPricing, useCustomerPricing } from './useAccountCharges';

export interface ISiteCostsHistory {
  id: string;
  invoiceMonth: number;
  invoiceYear: number;
  chargesAmount: number;
  activeUsers: number;
  currencyCode: CurrencyCodeString;
}

interface ISiteCostHistoryTotalNumber {
  totalNumber: number;
}

function getSiteCostsHistory(invoice: IInvoice, costs?: ICustomerPricing): ISiteCostsHistory {
  const date = new Date(invoice.invoiceDate);
  const timeZoneOffsetMs = date.getTimezoneOffset() * 60000;
  const invoiceDate = new Date(date.getTime() + timeZoneOffsetMs);
  const invoiceYear = invoiceDate.getFullYear();
  const invoiceMonth = invoiceDate.getMonth();

  const activeUsers = invoice.billableUnits.reduce((curr, prev) => curr + prev.numberOfUnits, 0);

  return {
    id: invoice.id,
    invoiceMonth,
    invoiceYear,
    chargesAmount: getTotalUnitsPrice(roundAsCurrencyOnUI(costs?.value ?? 0), activeUsers),
    activeUsers,
    currencyCode: getCurrencyCodeStringFromId(invoice.currencyCodeId),
  };
}

export function useSiteCostsHistoryInfinite(siteId: string | undefined,
  maxPage?: number,
  tableParams?: {
    scrollRef?: RefObject<HTMLDivElement | null>;
  }): IInfiniteWithPaginationModel<ISiteCostsHistory> {
  const company = useCompany();
  const { customerReference } = useClaims();
  const { costs } = useCustomerPricing();

  const invoicesUrl = !!costs && siteId
    ? getUrlWithQueryParams(Urls.Invoices(customerReference), {
      orderByYear: SortStringOptions.Descending,
      billableUnitUniqueId: siteId,
    })
    : undefined;

  const invoicesInfinite = useInfiniteWithPagination<IInvoice>(invoicesUrl, maxPage, tableParams);
  const { sitesSummary } = useSitesSummary({ search: '' });
  if (!invoicesInfinite.items || !company || !sitesSummary) {
    return { ...invoicesInfinite, items: [] };
  }
  const shouldFilterBillableUnitsBySiteName = !!siteId;
  if (shouldFilterBillableUnitsBySiteName) {
    invoicesInfinite.items = invoicesInfinite.items.map((invoice: IInvoice) => {
      const filteredBillableUnits = invoice.billableUnits.filter((unit: IBillableUnit) => unit.billableUnitUniqueId === siteId);
      return { ...invoice, billableUnits: filteredBillableUnits };
    });
  }
  const items = invoicesInfinite.items?.filter((p) => p.billableUnits?.length > 0)
    .map((p) => getSiteCostsHistory(p, costs!));
  return {
    ...invoicesInfinite,
    items,
  };
}

export function useSiteCostsHistoryTotalNumber(queryParams: IGridQueryParams & { billableUnitUniqueId?: string }): number | undefined {
  const { customerReference } = useClaims();
  const url = Urls.InvoicesTotalNumber(customerReference);
  const urlWithFilter = queryParams.billableUnitUniqueId
    ? getUrlWithQueryParams(url, queryParams)
    : undefined;
  const { data } = useSWRAndHandleErrors<ISiteCostHistoryTotalNumber>(urlWithFilter, httpGetJson);

  return data?.totalNumber;
}
