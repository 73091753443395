import { ICompanyBillingAddress, ICompanyInfo } from '../../../routes/systems/models/ICompany';
import { useSWRAndHandleErrors } from './errorHandling/useSWRAndHandleErrors';
import { httpGetJson, httpPost, httpPut } from '../../backend/http/http';
import { Urls } from '../../backend/urls';
import { AccountType, IInternalAppsCompanyAddress } from './useKeyContact';

export function useCompany() {
  const { data } = useSWRAndHandleErrors<ICompanyInfo>(Urls.Companies, httpGetJson);
  return data;
}

export function getCompany(): Promise<ICompanyInfo> {
  return httpGetJson<ICompanyInfo>(Urls.Companies);
}

export function getCompanyByCustomerReference(customerReference: string): Promise<ICompanyInfo> {
  return httpGetJson<ICompanyInfo>(Urls.CompanyByCustomerReference(customerReference));
}

export function acceptTermsAndConditions(): Promise<unknown> {
  return httpPut(Urls.CompaniesTermsAndConditions, {});
}

enum ApiCompanyAccountType {
  Unkown = 0,
  Installer,
  RegisteredInstaller,
  SystemManager
}

function getApiCompanyAccountType(accountType: AccountType): ApiCompanyAccountType {
  switch (accountType) {
    case AccountType.Installer:
      return ApiCompanyAccountType.Installer;
    case AccountType.RegisteredInstaller:
      return ApiCompanyAccountType.RegisteredInstaller; case AccountType.SystemManager:
      return ApiCompanyAccountType.SystemManager;
    default:
      return ApiCompanyAccountType.Unkown;
  }
}

export function setCompanyFields(address: IInternalAppsCompanyAddress, accountType: AccountType): Promise<unknown> {
  const billingAdress: ICompanyBillingAddress = {
    city: address.city,
    state: address.country,
    address1: address.address1,
    address2: address.address2,
    postCode: address.postCode,
    countryCode: address.countryId.toString(),
  };

  return httpPut(Urls.CompaniesFields, {
    accountType: getApiCompanyAccountType(accountType),
    address: billingAdress,
  });
}

export function submitVatNumber(vatNumber: string): Promise<unknown> {
  return httpPost(Urls.CompaniesVatNumber, JSON.stringify({ VatNumber: vatNumber }));
}

export async function uploadTaxCertificate(file?: File | null) {
  if (!file) {
    return;
  }

  const formData = new FormData();
  formData.append('file', file);
  await httpPost(Urls.CompaniesTaxCertificate, formData);
}
