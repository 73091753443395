import { useTranslation } from 'react-i18next';
import { useLocation, useRoute } from 'wouter';
import {
  InvoiceStatus,
  useInvoice,
  useInvoiceStatusOptions,
} from '../../shared/appUIFramework/appBackend/useInvoices';
import { AppPermissionComponentGuard } from '../../shared/appUIFramework/appPermisions/AppPermisionGuard';
import PaymentHeader from './components/PaymentHeader';
import { getInvoiceStatusColorClassName, useDownloadInvoice } from './invoice.helper';
import { UserPermission } from '../../shared/appUIFramework/appPermisions/userPermission';
import InvoicePdfPreview from './components/InvoicePdfPreview';
import { ReactComponent as CloseIcon } from '../../assets/icons/Text field icons/Close icon.svg';
import './PaymenInvoicePdf.scss';
import AppShowLoading from '../../shared/appUIFramework/components/AppShowLoading';
import { Pay } from '../../shared/backend/auth/claims';
import { isManualPaymentAllowed, useInternalAppsCompanyDetails } from '../../shared/appUIFramework/appBackend/useKeyContact';

export default function PaymentInvoicesPdf() {
  const [, params] = useRoute('/payment/overview/:invoiceId');
  const invoiceId = +params!.invoiceId!;
  const { invoice } = useInvoice(+invoiceId!);
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { getOptionLabel: getInvoiceStatusTranslation } = useInvoiceStatusOptions();

  const downloadPdf = useDownloadInvoice(invoice);

  const goToPayments = () => {
    setLocation('/payment/overview');
  };

  const makeOneTimePayment = async () => {
    if (invoice?.invoiceId) {
      await Pay(invoice?.invoiceId);
    }
  };

  const isInvoicePaid = () => {
    if (invoice) {
      return [InvoiceStatus.Paid, InvoiceStatus.Pending].includes(invoice?.invoiceStatusId);
    }
    return false;
  };

  const { data: company } = useInternalAppsCompanyDetails();

  return (
    <>
      <PaymentHeader activeTab="overview" />
      <div className="app-content app-content-with-tabs app-invoice-pdf">
        <AppShowLoading showLoading={invoice == null}>
          {invoice
                        && (
                        <>
                          <div
                            className="app-d-flex app-justify-content-between app-bg-primary-white-color app-invoice-pdf-header"
                          >
                            <div className="app-d-flex app-flex-direction-column">
                              <div
                                className={`app-invoice-pdf-currency ${getInvoiceStatusColorClassName(invoice.invoiceStatusId)}`}
                              >
                                {getInvoiceStatusTranslation(invoice.invoiceStatusId)}
                              </div>
                            </div>
                            <div className="app-d-flex">
                              <div className="app-d-flex app-align-items-center app-invoice-pdf-actions">
                                <button
                                  className="app-primary-button app-mw-101 app-weight-normal app-text-transform-none"
                                  type="button"
                                  onClick={downloadPdf}
                                >
                                  {`${t('SaveTo')} ${t('PDF')}`}
                                </button>
                                { !isInvoicePaid() && company && isManualPaymentAllowed(company) && (
                                <AppPermissionComponentGuard permissions={[UserPermission.CanMakePayments]}>
                                  <button
                                    className="app-primary-button app-uppercase app-mw-101 app-weight-normal"
                                    type="button"
                                    onClick={makeOneTimePayment}
                                  >
                                    {t('Pay')}
                                  </button>
                                </AppPermissionComponentGuard>
                                )}

                              </div>
                              <div className="app-text-align-right app-pl-51">
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                                <div className="app-cursor-pointer" role="button" onClick={goToPayments}>
                                  <CloseIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="app-flex-vertical-scrollable">
                            <InvoicePdfPreview invoice={invoice} />
                          </div>
                        </>
                        )}
        </AppShowLoading>
      </div>
    </>
  );
}
